import {Box, Center, Container, createStyles, Grid, Group, Image, List, Paper, Space, Stack, Tabs, Text} from "@mantine/core";
import {
    IconBooks,
    IconBorderNone, IconBrandAmazon, IconBrandAngular, IconBrandAsana,
    IconBrandBootstrap,
    IconBrandCss3, IconBrandDjango, IconBrandDocker, IconBrandGit,
    IconBrandHtml5,
    IconBrandJavascript, IconBrandMysql, IconBrandNpm, IconBrandPhp,
    IconBrandPython,
    IconBrandReact, IconBrandRedux, IconBrandSlack, IconBrandTrello, IconBrandVisualStudio, IconBrandVue,
    IconCode, IconDatabase, IconFileCode, IconFileCode2, IconFileDatabase, IconTools, IconWebhook
} from "@tabler/icons";
import {SkewedBox} from "./crm/CRMStyles";
import picofme from "../images/picofme.png";
import React from "react";
import {useMediaQuery} from "@mantine/hooks";

const useStyles = createStyles((theme)=>({
    margin: {
        marginTop: "60px",
        marginBottom: "60px"
    },
    large: {
        fontSize: "19px"
    }
}))

export function AboutMe() {

    const isTabletOrMobile = useMediaQuery('(max-width: 1224px)')
    const {classes, cx} = useStyles();

    return (
        <Grid h={"auto"} style={{position: 'relative', top: -50}} my={70} >
            <Grid.Col md={12} lg={12} sm={12} p={0} id={"about"}>
               <SkewedBox>

                <Box>
                    <Group>
                        <Center w={'100%'}>
                        <Stack p={'lg'} w={isTabletOrMobile ?'100%' :'55%'}>
                            <Text size={30} fw={900}>Hello!</Text>
                            <Text style={{fontSize: "17px"}}>My name is Michael. When I was 13 I started to teach myself software development as a means to be creative. my knowledge began by learning the ins-and-outs of Java programming. Eventually, my skills grew in other projects such as: designing UI and UX, setting up UNIX systems, and building fullstack web services. I am always excited to earn new opportunities to shape my Software Engineering career.</Text>
                        </Stack>

                        </Center>
                        {/*<Box w={150}>*/}
                        {/*<Image src={picofme} w={205} style={{filter: 'grayscale(0.3)'}}/>*/}
                        {/*</Box>*/}
                    </Group>
               </Box>
               </SkewedBox>
            </Grid.Col>


            </Grid>
    );
}
