import {createStyles, Grid, List, Paper, Space, Tabs, Text} from "@mantine/core";
import {
    IconBooks, IconBorderNone, IconBrandAmazon, IconBrandAngular, IconBrandAsana,
    IconBrandBooking, IconBrandBootstrap,
    IconBrandCss3, IconBrandDjango, IconBrandDocker, IconBrandGit,
    IconBrandHtml5, IconBrandJavascript, IconBrandMysql, IconBrandNpm, IconBrandPhp, IconBrandPython,
    IconBrandReact, IconBrandRedux, IconBrandSlack, IconBrandTrello, IconBrandVisualStudio, IconBrandVue,
    IconCode, IconDatabase, IconFileCode, IconFileCode2, IconFileDatabase,
    IconMessageCircle,
    IconPhoto,
    IconSettings, IconTools, IconWebhook
} from "@tabler/icons";
import React from "react";

const useStyles = createStyles((theme)=>({
    large: {
        fontSize: "19px",
        color: 'white'
    }
}))

export function Skills() {

    const {classes, cx} = useStyles();

    return (
        <Grid>
        <Grid.Col mt={'xl'} lg={12} sm={12} p={20} id={"skills"}>
            <Text size={40} fw={700} color={"blue.10"}>Skills</Text>
            <Tabs defaultValue="languages" styles={{
                tabsList: {
                    backgroundColor: '#228be6',
                    color: 'white',
                    "[data-active]": {
                        color: 'white !important',
                        fontWeight: 800
                    },

                    fontSize: 20,
                    padding: 15,
                    borderTopLeftRadius: 5,
                    borderTopRightRadius: 5
                },
                tab: {
                    "&:hover": {
                        backgroundColor: 'transparent',
                        border: 'none'
                    },
                },
                panel: {
                    backgroundColor: '#f3f3f3',
                    borderBottomLeftRadius: 5,
                    borderBottomRightRadius: 5,
                    color: 'gray'
                }
            }}>
                <Tabs.List>
                    <Tabs.Tab value="languages" className={classes.large} icon={<IconCode size={14} />}>Languages</Tabs.Tab>
                    <Tabs.Tab value="frameworks" className={classes.large} icon={<IconBooks size={14} />}>Libraries</Tabs.Tab>
                    <Tabs.Tab value="tools" className={classes.large} icon={<IconTools size={14} />}>Tools</Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="languages" pt="xs">
                    <List styles={{
                        item: {
                            color: 'gray'
                        }
                    }} spacing={"xs"} size={"lg"} withPadding>
                        <Grid>

                            <Grid.Col span={6}>
                                <List.Item icon={<IconBrandHtml5/>}>
                                    HTML 5
                                    <Text size={14}>Advanced (6 years)</Text>
                                </List.Item>
                                <List.Item icon={<IconBrandCss3/>}>
                                    CSS
                                    <Text size={14}>Advanced (6 years)</Text>
                                </List.Item>
                                <List.Item icon={<IconBrandJavascript/>}>
                                    JS
                                    <Text size={14}>Advanced (6 years)</Text>
                                </List.Item>
                                <List.Item icon={<IconCode/>}>
                                    Java
                                    <Text size={14}>Advanced (7+ years)</Text>
                                </List.Item>
                                <List.Item icon={<IconBrandPython/>}>
                                    Python
                                    <Text size={14}>Advanced (5 years)</Text>
                                </List.Item>
                                <List.Item icon={<IconBrandPhp/>}>
                                    PHP
                                    <Text size={14}>Advanced (5 years)</Text>
                                </List.Item>

                                <List.Item icon={<IconBrandMysql/>}>
                                    MYSQL, MariaDB, MongoDB
                                    <Text size={14}>Advanced (4-5 years)</Text>
                                </List.Item>

                                <List.Item icon={<IconBrandJavascript/>}>
                                    TypeScript
                                    <Text size={14}>Intermediate (2 years)</Text>
                                </List.Item>


                            </Grid.Col>
                            <Grid.Col span={6}>

                                <List.Item icon={<IconFileCode2/>}>
                                    C#
                                    <Text size={14}>Intermediate (3 years)</Text>
                                </List.Item>
                                <List.Item icon={<IconFileCode/>}>
                                    C
                                    <Text size={14}>Intermediate (3 years)</Text>
                                </List.Item>
                                <List.Item icon={<IconFileCode/>}>
                                    Swift
                                    <Text size={14}>Intermediate (2 years)</Text>
                                </List.Item>
                            </Grid.Col>
                        </Grid>
                    </List>
                </Tabs.Panel>

                <Tabs.Panel value="frameworks" pt="xs">
                    <List styles={{
                        item: {
                            color: 'gray'
                        }
                    }}  spacing={"xs"} size={"lg"} withPadding listStyleType={'none'}>
                        <Grid>
                            <Grid.Col span={6}>
                                <List.Item fw={800} style={{fontSize: 20}} inlist={false}>
                                    Front-End
                                </List.Item>
                                <List.Item icon={<IconBrandBootstrap/>}>
                                    Bootstrap
                                </List.Item>
                                <List.Item icon={<IconBrandReact/>}>
                                    React.js
                                </List.Item>

                                <List.Item icon={<IconBorderNone/>}>
                                    jQuery
                                </List.Item>
                                <List.Item icon={<IconBrandAngular/>}>
                                    Angular
                                </List.Item>
                                <List.Item icon={<IconBrandVue/>}>
                                    Vue
                                </List.Item>
                                <List.Item icon={<IconBorderNone/>}>
                                    Webpack
                                </List.Item>
                                <List.Item icon={<IconBrandRedux/>}>
                                    Redux
                                </List.Item>

                            </Grid.Col>

                            <Grid.Col span={6}>

                                <List.Item fw={800} style={{fontSize: 20}}>Back-End</List.Item>
                                <List.Item icon={<IconBrandJavascript/>}>
                                    Node.js
                                </List.Item>
                                <List.Item icon={<IconBrandJavascript/>}>
                                    Express.js
                                </List.Item>
                                <List.Item icon={<IconBrandDjango/>}>
                                    Django
                                </List.Item>
                                <List.Item icon={<IconBrandJavascript/>}>
                                    Jest
                                </List.Item>
                                <List.Item icon={<IconDatabase/>}>
                                    Sequelize
                                </List.Item>

                            </Grid.Col>
                        </Grid>
                    </List>
                </Tabs.Panel>

                <Tabs.Panel value="tools" pt="xs">
                    <List styles={{
                        item: {
                            color: 'gray'
                        }
                    }}  spacing={"xs"} size={"lg"} withPadding>
                        <Grid>
                            <Grid.Col span={6}>
                                <List.Item icon={<IconTools/>}>
                                    JetBrains IntelliJ, DataGrip, WebStorm, PyCharm
                                </List.Item>
                                <List.Item icon={<IconBrandVisualStudio/>}>
                                    Visual Studio
                                </List.Item>
                                <List.Item icon={<IconTools/>}>
                                    Eclipse
                                </List.Item>
                                <List.Item icon={<IconTools/>}>
                                    Jira
                                </List.Item>
                                <List.Item icon={<IconBrandTrello/>}>
                                    Trello
                                </List.Item>
                                <List.Item icon={<IconBrandAsana/>}>
                                    Asana
                                </List.Item>
                                <List.Item icon={<IconBrandSlack/>}>
                                    Slack
                                </List.Item>
                                <List.Item icon={<IconTools/>}>
                                    Jenkins
                                </List.Item>
                                <List.Item icon={<IconTools/>}>
                                    Travis CI
                                </List.Item>
                            </Grid.Col>

                            <Grid.Col span={6}>
                                <List.Item icon={<IconBrandDocker/>}>
                                    Docker
                                </List.Item>
                                <List.Item icon={<IconBrandMysql/>}>
                                    MySQL Workbench
                                </List.Item>
                                <List.Item icon={<IconTools/>}>
                                    Postman
                                </List.Item>
                                <List.Item icon={<IconTools/>}>
                                    Linux
                                </List.Item>
                                <List.Item icon={<IconBrandGit/>}>
                                    Git
                                </List.Item>
                                <List.Item icon={<IconTools/>}>
                                    Power-BI
                                </List.Item>
                                <List.Item icon={<IconBrandAmazon/>}>
                                    Amazon Web Services (AWS)
                                </List.Item>
                                <List.Item icon={<IconTools/>}>
                                    Microsoft Azure
                                </List.Item>

                            </Grid.Col>

                        </Grid>
                    </List>
                </Tabs.Panel>
            </Tabs>
            <Space m={50}></Space>
        </Grid.Col>
        </Grid>
    );
}