import Matter, {Events} from 'matter-js'
import {useEffect, useRef} from "react";
export default function PhysicsGame() {

    const scene = useRef<any>(null)
    function randomBlue () {
        return "rgb(0, 10, " + (Math.floor(Math.random() * 255)) + ")";
    }
    //Init scene
    useEffect(()=>{
        var Engine = Matter.Engine,
            Render = Matter.Render,
            Runner = Matter.Runner,
            Composite = Matter.Composite,
            Composites = Matter.Composites,
            Common = Matter.Common,
            MouseConstraint = Matter.MouseConstraint,
            Mouse = Matter.Mouse,
            Bodies = Matter.Bodies;

        // create engine
        var engine = Engine.create(),
            world = engine.world;


        // create renderer
        var render = Render.create({
            canvas: scene.current,
            engine: engine,
            options: {
                width: 300,
                height: 400,
                showAngleIndicator: true,
                background: 'transparent',
                wireframes: false
            },
        });

        Render.run(render);

        // create runner
        var runner = Runner.create();
        Runner.run(runner, engine);

        Composite.add(world, [
            // walls
            Bodies.rectangle(400, 0, 800, 50, { isStatic: true, render: { fillStyle: 'transparent'} }),
            Bodies.rectangle(400, 600, 800, 50, { isStatic: true, render: { fillStyle: 'transparent'} }),
            Bodies.rectangle(800, 300, 50, 600, { isStatic: true, render: { fillStyle: 'transparent'} }),
            Bodies.rectangle(0, 300, 50, 600, { isStatic: true, render: { fillStyle: 'transparent'} })
        ]);

        var stack = Composites.stack(200, 200, 4, 4, 0, 0, function(x: number, y: number) {
            return Bodies.rectangle(x, y, 40, 40, {render: {fillStyle: randomBlue()}});
        });

        Composite.add(world, stack);

        engine.gravity.y = 0;

        Events.on(engine, 'afterUpdate', function(event) {
            var time = engine.timing.timestamp,
                timeScale = (10 || (1000 / 60)) / 1000;

            Composite.translate(stack, {
                x: Math.sin(time * 0.001) * 10 * timeScale,
                y: 0
            });

            Composite.rotate(stack, Math.sin(time * 0.001) * 0.75 * timeScale, {
                x: 300,
                y: 300
            });

            var scale = 1 + (Math.sin(time * 0.0007) * 0.7 * timeScale);

            Composite.scale(stack, scale, scale, {
                x: 300,
                y: 300
            });
        });

        // add mouse control
        var mouse = Mouse.create(render.canvas),
            mouseConstraint = MouseConstraint.create(engine, {
                mouse: mouse,
                constraint: {
                    stiffness: 0.2,
                    render: {
                        visible: false
                    }
                }
            });

        Composite.add(world, mouseConstraint);

        // keep the mouse in sync with rendering
        render.mouse = mouse;

        // fit the render viewport to the scene
        Render.lookAt(render, {
            min: { x: 0, y: 0 },
            max: { x: 800, y: 600 }
        });


    }, [])

        // context for MatterTools.Demo
        // @ts-ignore
    return (
            <canvas ref={scene} style={{ position: 'absolute', backgroundColor: 'transparent', overflow: 'scroll' }} />
        )


}