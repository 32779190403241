import React from 'react';
import logo from './logo.svg';
import './App.css';
import {HeaderSimple} from "./components/header";
import {Hero} from "./components/hero";
import {BackgroundImage, Box, Center, Container, Grid, Group, MantineProvider, Overlay, Text} from "@mantine/core";
import {AboutMe} from "./components/aboutme";
import {Skills} from "./components/skills";
import {Experience} from "./components/experience";
import {Projects} from "./components/projects";
import {Music} from "./components/music";
import {FooterSocial} from "./components/footer";
import backgroundIMG from './images/webb.png'
import PhysicsGame from "./components/PhysicsGame";
import Spotify from "react-spotify-embed";


export const links = [
    {link: "home", label: "Home"},
    {link: "about", label: "About"},
    {link: "skills", label: "Skills"},
    {link: "experience", label: "Experience"},
    {link: "projects", label: "Projects"} ,
    {link: "music", label: "Music"}]

export default function PortfolioPage() {


  return (
    <div className="App" style={{overflowX: "hidden"}}>
          <HeaderSimple links={links}></HeaderSimple>
        <BackgroundImage style={{
            backgroundSize: "contain",
            WebkitBoxShadow: ' inset 10px 10px 79px 28px rgba(152,205,240,0.5)',
            boxShadow: 'inset 10px 10px 79px 28px rgba(152,205,240,0.5)'
        }} opacity={0.9} src={backgroundIMG}>
        <Container pb={0} size={'lg'}>
                    <Hero/>
            </Container>
        </BackgroundImage>
                  <AboutMe/>

        <Container size={'lg'}>
            <Skills/>
                <Experience/>
                <Projects/>
                <Text size={40} fw={700} color={"blue.10"} pl={'md'}>Other Interests</Text>

            <Text size={35} pl={'md'} fw={500} color={'blue.9'}>Music</Text>
            <Grid m={10} py={70}>
                <Grid.Col span={"content"}>
                    <Text size={"sm"} color={"dimmed"}>(Producer, Drums)</Text>
                    <Center>
                        <Spotify link={"https://open.spotify.com/artist/5vAn0UxdU1ovz6g7WsFDHE?si=TBPM5Qj6QVm7pGN-Y-8NiA"}/>
                    </Center>
                </Grid.Col>
                <Grid.Col span={"content"}>

                    <Text size={"sm"} color={"dimmed"}>(Producer, Drums)</Text>
                    <Center>
                        <Spotify link={"https://open.spotify.com/artist/2e6rBejQPMCcdfE4V3AnBK?si=4Vj2PGyYQSK_HL69zzZE6A"}/>
                    </Center>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Music/>
                </Grid.Col>
            </Grid>



            <Box h={200}>
                <Center h={200}>
                    <PhysicsGame/>
                </Center>
            </Box>
            </Container>


            <FooterSocial/>
    </div>
  );
}