import {
    Avatar,
    Text,
    Burger,
    Container,
    Group,
    Image,
    Header,
    Stack,
    Center,
    createStyles,
    Grid,
    Paper, List, Space, Button, Input, NumberInput, Box, Divider
} from "@mantine/core";
import {IconBrandGithub, IconMail, IconMapPin} from "@tabler/icons";
import AbstractShape from "../images/cool-abstract-shape.png"
import React, {useEffect, useState} from "react";
import Spotify from "react-spotify-embed";
import { HeaderSimple } from "../header";
import { FooterSocial } from "../footer";
import Youtube from "react-youtube";
import {useLocation} from "react-router-dom";


const useStyles = createStyles((theme)=>({
    links: {
        color: "#1a70b3"
    },

    margin: {
        margin: "15px"
    }
}))


export function LoraSensorDashboard() {

    const {classes} = useStyles()

    const {pathname, hash} = useLocation()

    useEffect(() => {
        // "document.documentElement.scrollTo" is the magic for React Router Dom v6
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "auto",
        })

    }, [pathname, hash]);
    return (
        <div id={"music"}>
            <HeaderSimple links={[]}/>
            <Box bg={'blue.4'} h={300}>
                <Center h={'100%'} >
                    <Stack spacing={0}>
                        <Text color={'white'} align={'center'} size={50} fw={800}>Lora Sensor Dashboard</Text>
                        <Text align={'center'} color={'gray.2'}>LoRa Sensor Integration on the Helium Network with PHP-HTML Front-End Dashboard</Text>
                    </Stack>
                </Center>
            </Box>
            <Container py={'lg'}>


                <Text size={'lg'}>Video Demo</Text>
                <Youtube videoId={'rV7MoZKeTWE'}/>

                <Text fw={900} size={'xl'} mt={'xl'} color={'gray.8'}>Project Overview</Text>

                <Text>
                    In this project, I successfully integrated LoRa sensors into the Helium Network, a decentralized and scalable blockchain-based wireless network for the Internet of Things (IoT). Additionally, I developed a user-friendly PHP-HTML front-end dashboard to visualize and manage the data collected by these sensors. This project aimed to provide a robust and accessible solution for monitoring and managing IoT sensor data using cutting-edge technologies.

                    <Divider my={20}/>
                    <b>LoRa Sensor Integration</b>: The primary goal was to set up LoRa sensors capable of capturing various environmental data, such as temperature, humidity, and air quality, and transmitting this data securely over the Helium Network.
                    <Divider my={20}/>
                    <b>Helium Network Integration</b>: The project involved configuring LoRaWAN gateways compatible with the Helium Network to establish a reliable communication link between the sensors and the network.
                    <Divider my={20}/>
                    <b>Data Aggregation and Storage</b>: The collected sensor data was aggregated and stored securely on the Helium Network. This step ensured the data's integrity and accessibility for further analysis.
                    <Divider my={20}/>
                    <b>PHP-HTML Front-End Dashboard</b>: I developed a user-friendly web-based dashboard using PHP and HTML to visualize real-time and historical data from the LoRa sensors. The dashboard allowed users to view data trends, set alerts, and manage sensor configurations.
                    <Divider my={20}/>
                    <b>User Authentication and Security</b>: To ensure data security and privacy, the dashboard included user authentication features. Only authorized users could access and interact with the sensor data.
                    <Divider my={20}/>
                    <b>Alerting Mechanism</b>: The system was designed to send notifications and alerts to users in case of critical sensor readings, enabling proactive response to environmental changes.
                    <Divider my={20}/>
                    <b>Scalability and Performance</b>: The project aimed for scalability by ensuring that the system could handle a growing number of sensors without compromising performance. This was achieved through proper architecture and database design.
                </Text>

            </Container>
            <FooterSocial/>
        </div>
    )

}
