import {
    Avatar,
    Text,
    Burger,
    Container,
    Group,
    Image,
    Header,
    Stack,
    Center,
    createStyles,
    Grid,
    Paper, List, Space, Button, Input, NumberInput, Box
} from "@mantine/core";
import {IconBrandGithub, IconMail, IconMapPin} from "@tabler/icons";
import AbstractShape from "../images/cool-abstract-shape.png"
import React, {useState} from "react";
import Spotify from "react-spotify-embed";
import {HeaderSimple} from "./header";
import {FooterSocial} from "./footer";
import {Reaplay} from "reaplay";
import PhysicsGame from "./PhysicsGame";
import ZipCodeAPI from "../images/ZipCodeAPI.png";
import ZipCodeAPIDocs from "../images/ZipCodeAPI-DOCS.png";
import ZipCodeAPIExamples from "../images/ZipCodeAPI-Example.png";

import DrumsImage1 from "../images/music/drums.jpg"
import DrumsImage2 from "../images/music/drum2.jpg"
import DrumsImage3 from "../images/music/moving.jpg"
import DrumsImage4 from "../images/music/DSC_1299.jpg"
import ImageGallery from "react-image-gallery";
import {useMediaQuery} from "@mantine/hooks";


const useStyles = createStyles((theme)=>({
    links: {
        color: "#1a70b3"
    },

    margin: {
        margin: "15px"
    }
}))

interface ExperienceList{

}

const audioGuitarTracks = [
            'https://drive.google.com/file/d/1fsJyWcPwO-Bee1d2YjFDlsDFsXnBxNWZ/view?usp=drive_link',
            'https://drive.google.com/file/d/1g6Knm4wkOek1V0fX0QXCOjqAEWlv1INR/view?usp=drive_link',
]

const items = [
    {
        original: DrumsImage2,
        thumbnail: DrumsImage2
    },
    {
        original: DrumsImage3,
        thumbnail: DrumsImage3
    },
    {
        original: DrumsImage4,
        thumbnail: DrumsImage4
    },
    {
        original: DrumsImage1,
        thumbnail: DrumsImage1
    },
]


export function Music() {
    const isTabletOrMobile = useMediaQuery('(max-width: 1224px)')


    const {classes} = useStyles()

    return (
<div id={"music"}>
        <Text size={28} fw={700} className={classes.margin} color={"blue.10"}>Gallery</Text>
        <Box w={isTabletOrMobile ? '100%' : '50%'} h={'auto'}>
        <ImageGallery items={items} lazyLoad={true}/>
        </Box>

</div>
    )

}
