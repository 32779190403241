import {Badge, Box, Button, createStyles, Group, List, Paper, Space, Tabs, Text, Image, Grid} from "@mantine/core";
import {
    IconBrandBooking,
    IconBrandBootstrap,
    IconBrandCss3,
    IconBrandHtml5,
    IconBrandJavascript,
    IconBrandMysql,
    IconBrandNpm,
    IconBrandPhp,
    IconBrandPython,
    IconBrandReact,
    IconCamera,
    IconCode,
    IconDatabase,
    IconDoor,
    IconFileCode,
    IconFileCode2,
    IconFileDatabase,
    IconGlass,
    IconMessageCircle,
    IconPhoto,
    IconUsers,
    IconSettings,
    IconSquare,
    IconWand,
    IconWebhook,
    IconWindow,
    IconDeviceDesktop,
    IconLayoutDashboard,
    IconApps,
    IconWifi
} from "@tabler/icons";
import React, {useState} from "react";
import Youtube from "react-player/youtube";
import {useNavigate} from "react-router-dom";
import ContactForm from "./ContactForm";

import PalmWebsiteImage from '../images/PalmSite.png'
import CocoImage from '../images/coco-splash.png'

import ZipCodeAPI from '../images/ZipCodeAPI.png'

import Masonry from 'react-masonry-css'
import {useMediaQuery} from "@mantine/hooks";

const useStyles = createStyles((theme)=>({
    large: {
        fontSize: "19px"
    },
    cardHover: {
        transform: "scale(1.01, 1.01)",
        transition: "0.05s",
        cursor:'pointer'
    },
    margin: {
        margin: "2px"
    }
}))

export function Projects() {

    const {classes, cx} = useStyles()
    const nav = useNavigate()

    const projectList = [
        {
            title: "Coco CRM",
            image: CocoImage,
            icon: <IconUsers/>,
            description: "A CRM project with a bunch of basic to advanced sales features",
            tags: ['React', "Express", "Node", "Sequelize", "MariaDB"],
            link: '/projects/palm_crm'
        },
        {
            title: "Palm Website",
            image: PalmWebsiteImage,
            icon: <IconDeviceDesktop/>,
            description: "Website for Palm Connectivity and the CRM project",
            tags: ['React', "Vite"],
            link: '/projects/palm_website'
        },

        {
            title: "ZIP Code API",
            icon: <IconDeviceDesktop/>,
            description: "API for retrieving to ZIP Code Data",
            tags: ['Node.JS', "Express", "Sequelize", 'AWS EC2'],
            link: '/projects/zipcode',
            image: ZipCodeAPI
        },
        {
            title: "SoTellUs Dashboard",
            icon: <IconLayoutDashboard/>,
            description: "Contributed to dashboard for SoTellUs customers to interact with their accounts",
            tags: ['React', "Vite", 'PHP'],
            link: null
        },
        {
            title: "LoRa Sensor Dashboard",
            icon: <IconWifi/>,
            description: "Frontend and firmware for device",
            tags: ['React', "Vite"],
            link: '/projects/lora_dashboard'
        },
        {
            title: "Smart Mirror",
            icon: <IconSquare/>,
            description: "A smart mirror I built from scratch as a means to test my software and hardware knowledge.",
            tags: ["Python", "Hardware"],
            date: "May 2016"
        },
    ]

    const legacyProjects = [
        {
            title: "Experience Hub",
            icon: <IconWand/>,
            description: "A web panel for users on McAmusement to experience additional features such as synced audio, achievements, and cosmetics.",
            tags: ["Java", "MySQL", "HTML", "JS", "PHP"],
            date: "May 2015"
        },
        {
            title: "Monty Hall Simulator",
            icon: <IconDoor/>,
            description: "Simple proof of the monty hall problem.",
            tags: ["Java"],
            date: "October 2018"
        },
        {
            title:  "Kinect Animatronics",
            icon: <IconCamera/>,
            description: "Records an animation of the position and rotation of your limbs using the Kinect SDK.",
            tags: ["C#", "Java"],
            date: "May 2015"
        }
    ]

    const [projects, setProjects] = useState(projectList);
    const [hover, setHover] = useState("")

    const isTabletOrMobile = useMediaQuery('(max-width: 1224px)')
    return (
        <div id={"projects"}>


            <Text size={40} fw={700} className={classes.margin} color={"blue.10"}>Projects</Text>
            <Masonry className={'my-masonry-grid'} breakpointCols={isTabletOrMobile ? 1 : 2} columnClassName={'my-masonry-grid_column'}>
            {projects.map((proj)=>{
                return(
                    <div style={{width: '100%'}}>
                <Paper key={proj.title} onClick={()=>{
                    if(proj.link){
                        nav(proj.link)
                    }
                }} className={hover!="" && hover==proj.title ? classes.cardHover : ""} onMouseOver={()=>setHover(proj.title)} onMouseOut={()=>setHover("")} withBorder shadow={"sm"} p={0} m={5} radius={"md"} bg={"white.3"}>

                    {proj.image ?
                        <Image mb={'xl'} src={proj.image}/>
                    : <></>}
                    <Box p={15}>
                    <Group>
                    <Text size={"xl"} fw={600}>{<span style={{verticalAlign: "middle", color: 'gray', fontSize: '10px !important'}}>{proj.icon}</span>} {proj.title}</Text>
                    </Group>
                    <Text size={"md"} fw={400}>{proj.description}</Text>
                    <Group spacing={5}>
                    {proj.tags.map((tag)=>{
                        return (
                            <Badge radius={5}>{tag}</Badge>
                        )
                    })}
                    </Group>
                    <Space my={"md"}/>

                    {proj.link ? <>
                    <Text size={"sm"} color={"dimmed"}>This project has a post associated</Text>

                    <Button variant={"outline"} size={"xs"} mt={10} onClick={()=>{
                        nav(proj.link)
                    }}>Read more</Button>

                        </>
                    : <></>}


                    </Box>
                </Paper>
                    </div>
                )
            })}
            </Masonry>
        </div>
    )
}
